import React from "react";
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter } from "react-icons/fa";
import "./FixedSocialMediaBox.css";

const FixedSocialMediaBox = () => {
  return (
    <div className="fixed-social-box">
      <div className="social-icon">
        <a
          href="https://www.facebook.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
        >
          <FaFacebook size={20} />
        </a>
      </div>
      <div className="social-icon">
        <a
          href="https://www.instagram.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
        >
          <FaInstagram size={20} />
        </a>
      </div>
      <div className="social-icon">
        <a
          href="https://www.youtube.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="YouTube"
        >
          <FaYoutube size={20} />
        </a>
      </div>
      <div className="social-icon">
        <a
          href="https://www.twitter.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Twitter"
        >
          <FaTwitter size={20} />
        </a>
      </div>
    </div>
  );
};

export default FixedSocialMediaBox;
