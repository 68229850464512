import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Pages/Home/Home";
import Servicecard from "./Components/Pages/Servicecard/Servicecard";
import Layout from "./Layout";
import { ThemeProvider } from "./context/ThemeContext";
import "./App.css";
import PageNotFound from "./Components/pagenotfound/PageNotFound";

const App = () => {
  return (
    <>
      <ThemeProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </ThemeProvider>
    </>
  );
};

export default App;
