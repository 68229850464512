import React, { useState, useEffect } from "react";
import "./Servicecard.css";
import servicimg from "./service1.jpg";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import client from "../../Common/Client";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GradientBackgroundWithToggle from "../homeBackgroundGradient/GradientBackgroundWithToggle";
import AxiosInstance from "../../../api/AxiosInstance";

const Servicecard = () => {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [service, setService] = useState([]);

  useEffect(() => {
    fetchService();
  }, []);

  const fetchService = async () => {
    console.log("fetchservicerun");

    try {
      const response = await AxiosInstance.get("/service/get-service");
      if (response.status === 200) {
        setService(response.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log(service);

  return (
    <>
      <section className="mt-4 mb-4">
        <div className="global-service">
          <div className="global-service-title">
            <h2>
              WE ARE BEST <span>IN</span>
            </h2>
          </div>
          <div className="global-service-content">
            <p>
              Managing Assets, Supporting Lives –
              <span>Your Trusted Partner in Property and Care.</span>
            </p>
          </div>
        </div>
        <div className="container mt-5" style={{ marginBottom: "100px" }}>
          <div className="slider-container">
            <Slider {...settings}>
              {service &&
                service.map((eachService) => {
                  return (
                    <div className="mt-3" key={eachService._id}>
                      <div className="global-card">
                        <div className="imgbx">
                          <img src={eachService.image} className="img" />
                        </div>
                        <div className="global-content ">
                          <span className="price">
                            <p>{eachService.serviceName}</p>
                          </span>

                          <div className="p-3 mt-2 global-list">
                            <h5 className="mb-2">Services :</h5>
                            {eachService.serviceAbout.map(
                              (eachServAbout, index) => {
                                const isEndWithPull =
                                  eachServAbout.endsWith(".");
                                const isLastItem =
                                  index === eachService.serviceAbout.length - 1;

                                return (
                                  <span>
                                    {eachServAbout}
                                    {!isLastItem && " | "}
                                  </span>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>
          {/* <div className="row">
        
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Servicecard;
