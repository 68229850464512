import React, { createContext, useState, useEffect } from "react";

import AxiosInstance from "../api/AxiosInstance";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const savedTheme = localStorage.getItem("theme") || "light";

  const [theme, setTheme] = useState(savedTheme);

  const [GtInfo, setGtInfo] = useState([]);

  useEffect(() => {
    const fetchAtozInfo = async () => {
      try {
        const response = await AxiosInstance.get("/contact/get-contact");
        const data = response.data;
        setGtInfo(data);
        // console.log("data", data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchAtozInfo();
  }, []);

  useEffect(() => {
    document.body.className = theme;

    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, GtInfo }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
