import React, { useContext } from "react";
import ThemeContext from "../context/ThemeContext";
import "./WhatsaApp.css"; // Ensure this includes your styles
import { FaWhatsapp } from "react-icons/fa"; // Using a WhatsApp icon from react-icons

export default function WhatsApp() {
  const { GtInfo } = useContext(ThemeContext);
  const phoneNumber = GtInfo[0]?.whatsapp || "+91 99620 20916";

  const handleClick = () => {
    const message = "Hello, how can we help you?"; // Default message
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="whatsapp-container">
      <button className="whatsapp-button" onClick={handleClick}>
        <FaWhatsapp size={34} />
      </button>
    </div>
  );
}
