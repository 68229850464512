import React, { useContext } from "react";
import ThemeContext from "../context/ThemeContext";
import "./WhatsaApp.css";
import { FaPhoneVolume } from "react-icons/fa6";

export default function PhoneFloading() {
  const { GtInfo } = useContext(ThemeContext);
  const phoneNumber = GtInfo[0]?.phoneIndia || "919876543210";

  return (
    <div className="phone-float-container">
      <a href={`tel:+91${phoneNumber}`}>
        <button className="float-button">
          <FaPhoneVolume size={30} />
        </button>
      </a>
    </div>
  );
}
