import React from "react";
import "./WhyChoose.css";
import hassle_free from "../../../assets/about/whyChoose/hassle-free.png";
import transparency from "../../../assets/about/whyChoose/transparency.png";
import responsible from "../../../assets/about/whyChoose/responsible.png";

import { motion } from "framer-motion";

const WhyChoose = () => {
  return (
    <section className="popular-places-section pt-5 ">
      <section className="popular_place padding_top ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className=" text-center">
                <motion.h2
                  className="site-title my-3"
                  initial={{ x: 100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.3, delay: 0.2 }}
                >
                  WHY CHOOSE
                </motion.h2>
                <motion.p
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.3, delay: 0.2 }}
                >
                  Choosing professional property management ensures your
                  investment is efficiently maintained, tenants are well-served
                  by Adhoc Services, and your maximize for your ROI while
                  minimizing stress and legal risks
                </motion.p>
              </div>
            </div>
          </div>
          <motion.div
            className="row justify-content-center my-5"
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            <div className="col-lg-4 col-sm-6">
              <div className="single_popular_place">
                <img src={hassle_free} alt="hassle_free" width="100%" />
                <h4>Hassle-Free Management</h4>
                <p>
                  NRIs can have peace of mind knowing that their properties are
                  well-maintained and rented out without needing their physical
                  presence.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="single_popular_place">
                <img src={transparency} alt="transparency" />
                <h4>Transparency</h4>
                <p>
                  Detailed reports and updates on property conditions and
                  finances are provided regularly.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="single_popular_place">
                <img src={responsible} alt="responsible" />
                <h4>Comprehensive Service</h4>
                <p>
                  GLOBAL TAMIZHAN offers end-to-end property management, from
                  tenant acquisition to financial reporting and legal
                  assistance.
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    </section>
  );
};

export default WhyChoose;
