import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Components/Pages/header/navbar/Navbar";
import LightDarkMode from "./Components/lightDarkMode/LightDarkMode";
import Footer from "./Components/Pages/footer/Footer";
import WhatsApp from "./socialMedia/Whatsapp";
import PhoneFloading from "./socialMedia/PhoneFloading";
import FixedSocialMediaBox from "./Components/socialIcons/FixedSocialMediaBox";

const Layout = () => {
  return (
    <>
      <Navbar />
      <FixedSocialMediaBox />
      <main>
        <Outlet />
      </main>
      {/* <LightDarkMode /> */}
      <WhatsApp />
      <PhoneFloading />
      <Footer />
    </>
  );
};

export default Layout;
