import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Row, Col, Card, Button } from "react-bootstrap";
import AxiosInstance from "../../../api/AxiosInstance";
import { message } from "antd";

import { FaArrowRight } from "react-icons/fa6";

import "./BookingForm.css";
import ServicesDropdown from "./ServicesDropdown";

const BookingForm = () => {
  const [isLink, setIsLink] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [service, setService] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  const successMessage = () => {
    messageApi.open({
      type: "success",
      content: "Booking confirmed successfully!",
      duration: 2,
    });
  };

  const errorMessage = () => {
    messageApi.open({
      type: "error",
      content: "Failed to confirm booking.",
      duration: 2,
    });
  };

  useEffect(() => {
    fetchService();
  }, []);

  const fetchService = async () => {
    console.log("Fetching services...");

    try {
      const response = await AxiosInstance.get("/service/get-service");
      if (response.status === 200) {
        setService(response.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .test(
        "no-leading-space",
        "Name cannot start with a space",
        (value) =>
          typeof value === "string" && !/^\s/.test(value) && value.length > 0
      )
      .matches(/^[A-Za-z\s]+$/, "Name can only contain letters and spaces")
      .max(20, "Name cannot be longer than 20 characters")
      .required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .test(
        "no-leading-space",
        "email cannot start with a space",
        (value) =>
          typeof value === "string" && !/^\s/.test(value) && value.length > 0
      )
      .required("Email is required"),
    phone: Yup.string()
      .matches(
        /^[1-9]\d{9}$/,
        "Must be a valid phone number without leading zeros"
      )
      .required("Phone number is required")
      .required("Phone number is required"),
    services: Yup.array()
      .of(Yup.string().required("Service ID must be a string"))
      .min(1, "Please select at least one service")
      .required("Services are required"),
    link: Yup.string().nullable(),
    doorNo: Yup.string()
      .max(10, "doorNo cannot be longer than 10 characters")
      .nullable(),
    street: Yup.string()
      .max(50, "street cannot be longer than 50 characters")
      .nullable(),
    area: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Area can only contain letters and spaces")
      .max(50, "area cannot be longer than 50 characters")
      .nullable(),
    city: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "city can only contain letters and spaces")
      .max(50, "city cannot be longer than 50 characters")
      .nullable(),
    pincode: Yup.string()
      .matches(
        /^[1-9]\d{5}$/,
        "Pincode must be exactly 6 digits and cannot start with 0"
      )
      .nullable(),
    landmark: Yup.string()
      .max(50, "landmark cannot be longer than 50 characters")
      .nullable(),
  });

  const handleLinkToggle = (setFieldValue) => {
    setIsLink((prev) => {
      const newLinkState = !prev;

      if (newLinkState) {
        // Reset address fields when link is used
        setFieldValue("doorNo", "");
        setFieldValue("street", "");
        setFieldValue("area", "");
        setFieldValue("city", "");
        setFieldValue("pincode", "");
        setFieldValue("landmark", "");
      } else {
        // Reset link field when address is used
        setFieldValue("link", "");
      }

      return newLinkState;
    });
  };

  const handleSubmit = async (values, { resetForm }) => {
    const selectedServices = values.services;
    if (isLink && !values.link) {
      alert("Link is required when using the link option.");
      return;
    }

    if (!isLink) {
      const requiredFields = [
        "doorNo",
        "street",
        "area",
        "city",
        "pincode",
        "landmark",
      ];
      const missingFields = requiredFields.filter((field) => !values[field]);
      if (missingFields.length > 0) {
        alert(`Please fill in all address fields: ${missingFields.join(", ")}`);
        return;
      }
    }

    const data = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      services: selectedServices,
      link: isLink ? values.link : null,
      address: isLink
        ? null
        : {
            doorNo: values.doorNo,
            street: values.street,
            area: values.area,
            city: values.city,
            pincode: values.pincode,
            landmark: values.landmark,
          },
    };

    try {
      const response = await AxiosInstance.post("/booking/add-booking", data);
      successMessage();
      setFormSubmitted(true);
      resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
      errorMessage();
    }
  };

  return (
    <section className="form-section-container">
      {contextHolder}
      <div className="container">
        <Row className="my-5">
          <Col md={12}>
            <div>
              <h3 className="mb-5">Booking Form</h3>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phone: "",
                  services: [],
                  link: "",
                  doorNo: "",
                  street: "",
                  area: "",
                  city: "",
                  pincode: "",
                  landmark: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, isSubmitting, setErrors, setFieldValue }) => (
                  <Form>
                    {/* Fields */}
                    <Row className="mb-3">
                      <Col md={6} className="form-col-group">
                        <label htmlFor="name">Name</label>
                        <Field
                          type="text"
                          name="name"
                          placeholder="Enter a Name"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md={6} className="form-col-group">
                        <label htmlFor="email">Email</label>
                        <Field
                          type="email"
                          name="email"
                          placeholder="Enter a Email"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md={6} className="form-col-group">
                        <label htmlFor="phone">Phone</label>
                        <Field
                          type="text"
                          name="phone"
                          placeholder="Enter a phone Number"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md={6} className="form-col-group">
                        <ServicesDropdown
                          services={service} // Pass the service options array
                          values={values} // Formik values
                          setFieldValue={setFieldValue} // Formik setFieldValue function
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md={6} className="form-col-group">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            id="isLink"
                            checked={isLink}
                            onChange={() => handleLinkToggle(setFieldValue)}
                            className="form-check-input"
                          />
                          <label className="form-check-label" htmlFor="isLink">
                            Use a Link instead of Address
                          </label>
                        </div>
                      </Col>
                    </Row>

                    {isLink ? (
                      <Row className="mb-3">
                        <Col md={12} className="form-col-group">
                          <label htmlFor="link">Link - (Location Link)</label>
                          <Field
                            type="text"
                            name="link"
                            placeholder="Enter a Location Link"
                            className="form-control"
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              if (/^\s/.test(inputValue)) {
                                e.target.value = inputValue.trimStart();
                              }

                              setFieldValue("link", e.target.value);
                            }}
                          />
                          <ErrorMessage
                            name="link"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                      </Row>
                    ) : (
                      <>
                        <Row className="mb-3">
                          <Col md={6} className="form-col-group">
                            <label htmlFor="doorNo">Door No</label>
                            <Field
                              type="text"
                              name="doorNo"
                              placeholder="Enter a Door Number"
                              className="form-control"
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                if (/^\s/.test(inputValue)) {
                                  e.target.value = inputValue.trimStart();
                                }

                                setFieldValue("doorNo", e.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="doorNo"
                              component="div"
                              className="text-danger"
                            />
                          </Col>

                          <Col md={6} className="form-col-group">
                            <label htmlFor="street">Street</label>
                            <Field
                              type="text"
                              name="street"
                              placeholder="Enter a Street"
                              className="form-control"
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                if (/^\s/.test(inputValue)) {
                                  e.target.value = inputValue.trimStart();
                                }

                                setFieldValue("street", e.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="street"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md={6} className="form-col-group">
                            <label htmlFor="area">Area</label>
                            <Field
                              type="text"
                              name="area"
                              placeholder="Enter a Area"
                              className="form-control"
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                if (/^\s/.test(inputValue)) {
                                  e.target.value = inputValue.trimStart();
                                }

                                setFieldValue("area", e.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="area"
                              component="div"
                              className="text-danger"
                            />
                          </Col>

                          <Col md={6} className="form-col-group">
                            <label htmlFor="city">City</label>
                            <Field
                              type="text"
                              name="city"
                              placeholder="Enter a City"
                              className="form-control"
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                if (/^\s/.test(inputValue)) {
                                  e.target.value = inputValue.trimStart();
                                }

                                setFieldValue("city", e.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md={6} className="form-col-group">
                            <label htmlFor="pincode">Pincode</label>
                            <Field
                              type="text"
                              name="pincode"
                              placeholder="Enter a Pincode"
                              className="form-control"
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                if (/^\s/.test(inputValue)) {
                                  e.target.value = inputValue.trimStart();
                                }

                                setFieldValue("pincode", e.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="pincode"
                              component="div"
                              className="text-danger"
                            />
                          </Col>

                          <Col md={6} className="form-col-group">
                            <label htmlFor="landmark">Landmark</label>
                            <Field
                              type="text"
                              name="landmark"
                              placeholder="Enter a landmark"
                              className="form-control"
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                if (/^\s/.test(inputValue)) {
                                  e.target.value = inputValue.trimStart();
                                }

                                setFieldValue("landmark", e.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="landmark"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </Row>
                      </>
                    )}

                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                      className="animate-button mt-3 d-flex align-items-center"
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                      <FaArrowRight className="ms-2" />
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default BookingForm;
