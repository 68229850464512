import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Bookingform from "../Bookingform/Bookingform";
// import HeroSection from "../homeHeroSection/HeroSection";
import HomeAboutUs from "../homeAboutus/HomeAboutUs";
import BestService from "../HomeBestService/BestService";
import Herocoursel from "../Herocoursel/Herocoursel";

import Servicecard from "../Servicecard/Servicecard";
import WhyChoose from "../whyChoose/WhyChoose";


const Home = () => {
  const bookingFormRef = useRef(null);
  const aboutUsRef = useRef(null);
  const serviceRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#booking-form") {
      bookingFormRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (location.hash === "#about-us") {
      aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (location.hash === "#service") {
      serviceRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      {/* <HeroSection />   */}

      <Herocoursel />
      <div ref={aboutUsRef} id="about-us">
        <HomeAboutUs bgColor="#f9f7fa" />
      </div>

      <WhyChoose />
      <BestService />

      <div ref={serviceRef} id="service">
        <Servicecard />
      </div>

      <div ref={bookingFormRef} id="booking-form">
        <Bookingform />
      </div>
    </>
  );
};

export default Home;
