// GradientBackgroundWithToggle.js
import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import ThemeContext from "../../../context/ThemeContext";

// Define the keyframes for the animation
const auroraAnimation = keyframes`
  0% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 100% 100%;
  }
`;

// Styled component for the container
const GradientContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
  overflow: hidden;
  background-image: repeating-linear-gradient(
      100deg,
      #ffffff 0%,
      #ffffff 7%,
      transparent 10%,
      transparent 12%,
      #ffffff 16%
    ),
    repeating-linear-gradient(
      100deg,
      #3b82f6 5%,
      #a5b4fc1 5%,
      #93c5fd 10%,
      #c4b5fd 15%,
      #60a5fa 20%
    );
  background-size: 300%, 200%;
  background-position: 50% 50%, 50% 50%;
  filter: blur(10px);
  invert: 1;
  pointer-events: none;
  mask-image: radial-gradient(
    ellipse at 100% 0,
    #000 10%,
    var(--transparent) 70%
  );

  /* Dark mode background */
  &.dark {
    ${
      "" /* background-image: repeating-linear-gradient(
        100deg,
        #000000 0%,
        #000000 7%,
        transparent 10%,
        transparent 12%,
        #000000 16%
      ), */
    }
    repeating-linear-gradient(
        100deg,
        #3b82f6 5%,
        #a5b4fc 5%,
        #93c5fd 10%,
        #c4b5fd 15%,
        #60a5fa 20%
      );
    invert: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: repeating-linear-gradient(
        100deg,
        #ffffff 0%,
        #ffffff 7%,
        transparent 10%,
        transparent 12%,
        #ffffff 16%
      ),
      repeating-linear-gradient(
        100deg,
        #3b82f6 5%,
        #a5b4fc 5%,
        #93c5fd 10%,
        #c4b5fd 15%,
        #60a5fa 20%
      );
    background-size: 200%, 100%;
    animation: ${auroraAnimation} 10s infinite linear;
    background-attachment: fixed;
    mix-blend-mode: difference;
    opacity: 0.5;
    will-change: transform;
    mask-image: radial-gradient(ellipse at 100% 0%, black 10%, transparent 70%);
  }
`;

// Main Component
const GradientBackgroundWithToggle = ({ children }) => {
  const { theme } = useContext(ThemeContext); // Get the current theme

  return (
    <GradientContainer className={theme === "dark" ? "dark" : ""}>
      {children}
    </GradientContainer>
  );
};

export default GradientBackgroundWithToggle;
