import React from "react";
import "./HomeAboutUs.css";
import homeAboutImage from "../../../assets/about/about3.png";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { motion } from "framer-motion";

import { TbArrowBadgeRightFilled } from "react-icons/tb";

const HomeAboutUs = ({ bgColor }) => {
  return (
    <section style={{ backgroundColor: bgColor }}>
      <div className="container-fluid HomeAboutUsContainer">
        <motion.div
          className="HomeAboutUsLeftContainer"
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.3 }}
        >
          <img
            src={homeAboutImage}
            alt="About Us"
            className="img-fluid"
            width="600"
            height="400"
            loading="lazy"
          />
        </motion.div>
        <div className="HomeAboutUsRightContainer">
          <div
            className="HomeAboutHeaderContainer"
            style={{ overflow: "hidden" }}
          >
            <h6 className="HomeAboutHeader text-left">ABOUT US</h6>
            <h2 className="site-title mt-3">
              Reliable Property Management Solutions for NRIs –
              <span>Your Investment, Our Priority</span>
            </h2>
          </div>
          <motion.div
            initial={{ y: 200, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.4, delay: 0.3 }}
          >
            <p className="text-justify mt-3">
              <br />
              <span className="fw-bold">Welcome to Global Tamizhan</span>,
              Property management services for Non-Resident Indians (NRIs) in
              Tamilnadu, offering a range of services to help NRIs to manage and
              maintain their properties without having to be physically present
              with Safe & Secure.
            </p>
            <div className="about-list-wrapper">
              <ul className="about-list list-unstyled">
                <li>
                  <TbArrowBadgeRightFilled className="home-list-icons me-2" />
                  Reliable and efficient property management for NRIs, ensuring
                  your investment is in good hands.
                </li>
                <li>
                  <TbArrowBadgeRightFilled className="home-list-icons me-2" />
                  Professional team with expertise in managing residential and
                  commercial properties across India.
                </li>
                <li>
                  <TbArrowBadgeRightFilled className="home-list-icons me-2" />
                  Comprehensive services from property leasing to maintenance
                  and legal compliance.
                </li>
                <li>
                  <TbArrowBadgeRightFilled className="home-list-icons me-2" />
                  Transparent communication and 24/7 support, no matter where
                  you are in the world.
                </li>
              </ul>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default HomeAboutUs;
