import React from "react";
import "./BestService.css";
import { CiLocationOn } from "react-icons/ci";
import { LuUsers } from "react-icons/lu";

import visionImage from "../../../assets/about/vision.png";
import MissionImage from "../../../assets/about/business.png";

import { motion } from "framer-motion";

const BestService = () => {
  return (
    <section className="best-service">
      <div className="best-service-container container-fluid mt-5">
        <motion.div
          className="best-Service-left-container mt-5"
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.3 }}
        >
          <span className="sub-title">Our Vision & Our Mission</span>
          <div className="best-service-heading">
            <div className="site-heading">
              <h1 className="fw-bold my-3 text-light">
                Manage Your Properties with Confidence, No Matter Where You Are.
              </h1>
            </div>
          </div>
        </motion.div>
        <div className="best-Service-right-container p-3">
          <div className="d-flex flex-wrap">
            <motion.div
              initial={{ y: 200, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.4, delay: 0.3 }}
              className="service-right-container-box"
            >
              <div className="service-right-container-box-icon">
                <img
                  src={visionImage}
                  alt="global tamizhan vision images"
                  clssName="vision-image"
                  width="40px"
                  height="40px"
                />
              </div>
              <div className="">
                <h3 className="my-3 fw-bold text-theme-color">Vision</h3>
                <p className="para-dim about-vision-mission-para">
                  To be the trusted partner for Non-Resident Indians in managing
                  and enhancing their properties back home, ensuring peace of
                  mind, transparency, and sustainable value growth from afar.
                </p>
              </div>
            </motion.div>
            <motion.div
              initial={{ y: 200, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.4, delay: 0.3 }}
              className="service-right-container-box"
            >
              <div className="service-right-container-box-icon">
                <img
                  src={MissionImage}
                  alt="global tamizhan vision images"
                  clssName="vision-image"
                  width="40px"
                  height="40px"
                />
              </div>
              <div className="">
                <h3 className="my-3 fw-bold text-theme-color">Mission</h3>
                <p className="para-dim">
                  Our mission is to provide comprehensive and dependable
                  property management solutions that empower NRIs to manage,
                  protect, and grow their real estate assets in India from
                  anywhere in the world. Through clear communication, advanced
                  technology, and a commitment to integrity, we aim to deliver
                  seamless property care, maximize asset value, and give NRIs
                  the confidence that their properties are in expert hands.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BestService;
