import React, { useContext } from "react";
import slider_1 from "../../../assets/heroSection/banner-02.jpg";
import slider_2 from "../../../assets/about/aboutDocuments.jpg";
import slider_3 from "../../../assets/about/aboutParents.avif";
import { FaFacebook, FaInstagram, FaYoutube, FaEnvelope } from "react-icons/fa";
import { motion } from "framer-motion";
import "./Herocoursel.css";

function Herocoursel() {
  const textAnimation = {
    hidden: { opacity: 0, rotateX: -90 },
    visible: {
      opacity: 1,
      rotateX: 0,
      transition: { duration: 1, type: "spring", stiffness: 100 },
    },
  };

  return (
    <div className="herosection-coursel-container">
      <div
        id="carouselExampleIndicators"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        {/* Social Media Icons */}
        {/* <div className="social-icons">
          {[
            {
              icon: <FaFacebook />,
              link: "https://www.facebook.com/people/Praveen-Travels/pfbid02jvaM9WkG9zh9jzLrMnwsWnG4AhdsEkG83tRH6ttTYR89pFXMgB3JGRaD9Br2xUWtl/",
            },
            {
              icon: <FaInstagram />,
              link: "https://www.instagram.com/praveentravels2023",
            },
          ].map((item, index) => (
            <motion.a
              key={index}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ scale: 1.2 }}
              transition={{ type: "spring", stiffness: 300 }}
              className="social-icon"
            >
              {item.icon}
            </motion.a>
          ))}
        </div> */}

        <div className="carousel-inner">
          {/* First Slide */}
          <div className="carousel-item active">
            <img className="d-block w-100" src={slider_1} alt="First slide" />
            <div className="carousel-overlay">
              <span className="hero-carousel-overlay-title">
                Manage Your{" "}
                <motion.span
                  className="text-red-orange"
                  initial={{ y: 200, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.3 }}
                >
                  Property
                </motion.span>{" "}
                with{" "}
                <motion.span
                  className="text-theme-color"
                  initial={{ y: 200, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.3 }}
                >
                  Global Tamizhan
                </motion.span>
              </span>
            </div>
          </div>

          {/* Second Slide */}
          <div className="carousel-item">
            <img
              className="d-block w-100"
              width="100%"
              height="auto"
              src={slider_2}
              alt="Second slide"
            />
            <div className="carousel-overlay">
              <span className="hero-carousel-overlay-title">
                <motion.span
                  className="text-theme-color"
                  initial={{ y: 200, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.3 }}
                >
                  Global Tamizhan -{" "}
                </motion.span>
                Expert Legal <br /> Assistance for NRIs
              </span>
            </div>
          </div>

          {/* Third Slide */}
          <div className="carousel-item">
            <img className="d-block w-100" src={slider_3} alt="Third slide" />
            <div className="carousel-overlay">
              <span className="hero-carousel-overlay-title">
                <span className="text-red-orange">
                  {" "}
                  Care for Your Parents with Trusted <br /> Support from{" "}
                </span>
                <motion.span
                  className="text-theme-color"
                  initial={{ y: 200, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.3 }}
                >
                  {" "}
                  Global Tamizhan
                </motion.span>
              </span>
            </div>
          </div>
        </div>

        {/* Vertical Indicators */}
        {/* <ol className="carousel-indicators vertical-indicators">
          <li
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
          ></li>
          <li
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
          ></li>
          <li
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
          ></li>
        </ol> */}

        {/* Previous Button */}
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>

        {/* Next Button */}
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default Herocoursel;
