import React from "react";
import { Link } from "react-router-dom";
import "./PageNotFound.css";
import { IoWarningOutline } from "react-icons/io5";

const PageNotFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100 ">
      <div
        className="container-xxl py-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style={{
          visibility: "visible",
          animationDelay: "0.1s",
          animationName: "fadeInUp",
        }}
      >
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <IoWarningOutline className="text-primary fs-1 display-1" />
              <h1 className="display-1">404</h1>
              <h1 className="mb-4">Page Not Found</h1>
              <p className="mb-4">
                We’re sorry, the page you have looked for does not exist in our
                website! Maybe go to our home page or try to use a search?
              </p>
              <Link to="/" className="btn btn-primary py-3 px-5">
                Go Back To Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
